import React, { Fragment, Component } from 'react';
import { graphql } from 'gatsby';
import { ThemeProvider } from 'styled-components';
import { ResetCSS } from 'common/source/assets/css/style';
import { theme } from 'common/source/styles/theme';
import { GlobalStyle, ContentWrapper } from 'common/source/styles';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/source/contexts/DrawerContext';
import Navbar from 'common/source/containers/Navbar';
import Footer from 'common/source/containers/Footer';
import CategoryBanner from 'common/source/containers/CategoryBanner';
import SolutionList from 'common/source/containers/SolutionList';
// import ResourcesBanner from 'common/source/containers/ResourcesBannerList';
import CTABanner from 'common/source/containers/CTABanner';

import SEO from '../components/SEO';

class CategoryTemplate extends Component {
  render() {
    const { pageContext, data } = this.props;
    const { slug } = pageContext;
    // const { category, solutions, resources } = data;
    const { category, solutions } = data;
    const { frontmatter } = category;
    if (!frontmatter.id) {
      frontmatter.id = slug;
    }
    const categorySolutions = solutions.edges.map(edge => edge.node);
    // const allResources = resources.edges.map(edge => edge.node);
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          <SEO postPath={slug} postNode={category} postSEO prefix={frontmatter.prefix} />
          <ResetCSS />
          <GlobalStyle />

          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <CategoryBanner node={category} />
            <SolutionList category={category} solutions={categorySolutions} />
            {/* <ResourcesBanner heading={frontmatter.title} resources={allResources} /> */}
            <CTABanner />
            <Footer />
          </ContentWrapper>
        </Fragment>
      </ThemeProvider>
    );
  }
}

export default CategoryTemplate;
/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query CategoryBySlug($slug: String!) {
    category: markdownRemark(frontmatter: { slug: { eq: $slug }, type: { eq: "CATEGORY" } }) {
      html
      excerpt
      fields {
        slug
      }
      frontmatter {
        slug
        prefix
        title
        description
        cover
        thumbnail
      }
    }
    solutions: allMarkdownRemark(
      filter: { frontmatter: { category: { in: [$slug] }, type: { eq: "SOLUTION" } } }
      sort: { order: DESC, fields: [frontmatter___featured] }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            slug
            title
            cover
            thumbnail
            featured
            tags
          }
        }
      }
    }
    # resources: allMarkdownRemark(
    #   filter: { frontmatter: { type: { eq: "RESOURCE" }, category: { in: [$slug] } } }
    #   sort: { order: DESC, fields: [frontmatter___date] }
    # ) {
    #   edges {
    #     node {
    #       excerpt
    #       fields {
    #         slug
    #       }
    #       frontmatter {
    #         slug
    #         title
    #         cover
    #         thumbnail
    #         tags
    #       }
    #     }
    #   }
    # }
  }
`;
