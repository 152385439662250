import styled, { css } from 'styled-components';

const BannerWrapper = styled.section`
  padding-top: 290px;
  padding-bottom: 80px;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.yellow};
  ${props =>
    props.cover &&
    css`
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),
        url(${props.cover});
    `};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1440px) {
    padding-bottom: 80px;
  }
  @media (max-width: 990px) {
    padding-top: 250px;
    padding-bottom: 120px;
  }
  @media (max-width: 768px) {
    background-image: none;
    ${props =>
      props.cover &&
      css`
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),
          url(${props.cover});
      `};
  }

  @media only screen and (max-width: 480px) {
    padding-top: 130px;
    padding-bottom: 200px;
  }
  .product__specs {
    margin-top: 2rem;
    span {
      margin-left: 10px;
    }
    button {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  .feature__block {
    padding-left: 2rem;
    @media (max-width: 768px) {
      padding: 2rem;
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.white};
      letter-spacing: -0.025em;
      line-height: 1.2;
      margin-bottom: 25px;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
    }
    h1 {
      font-size: 4.5rem;
      @media (max-width: 1440px) {
        font-size: 3.85rem;
      }
      @media (max-width: 990px) {
        font-size: 3.5rem;
      }
      @media (max-width: 768px) {
        font-size: 3rem;
      }
      @media (max-width: 572px) {
        font-size: 2.5rem;
      }
    }
    p {
      color: ${({ theme }) => theme.colors.white};
      line-height: 24px;
      margin-bottom: 20px;
      max-width: 550px;
      font-size: 1.25rem;
    }
  }
  .row {
    position: relative;
    z-index: 1;
  }
  .button__wrapper {
    margin-top: 30px;
    display: flex;
    .reusecore__button {
      border-radius: 6px;
      &:first-child {
        transition: all 0.3s ease;
        ${'' /* margin-right: 30px; */}
        @media (max-width: 990px) {
          margin-right: 0;
        }
        ${'' /* &:hover {
          box-shadow: ${({ theme }) => theme.colors.primaryBoxShadow}
        } */}
      }
      &:nth-child(2) {
        padding-left: 0;
        @media (max-width: 1100px) {
          padding-left: 15px;
        }

        @media (max-width: 480px) {
          padding-left: 0;
          padding-top: 15px;
        }
        margin-top: 15px;
        .btn-text {
          font-weight: 700;
          margin-top: 7px;
        }
        .btn-icon {
          margin-top: 6px;
          margin-left: 6px;
        }
      }
      > a {
        font-weight: 700;
        color: #fff;
      }
    }
    .btnWithoutColor {
      margin-right: 15px !important;
      padding: 5px 0px !important;
      .btn-icon {
        svg {
          width: 30px;
          height: 30px;
        }
      }
      &:hover {
        box-shadow: none !important;
      }
    }
  }
`;

export default BannerWrapper;
