import React from 'react';
import { Link } from 'gatsby';
import Button from 'reusecore/src/elements/Button';
import { GridContainer, SolutionWrapper } from './solutionlist.style';

const FeaturedSolution = ({ solution }) => {
  if (!solution) {
    return null;
  }
  const {
    excerpt,
    frontmatter: { slug, title, cover }
  } = solution;
  return (
    <GridContainer template="auto">
      <SolutionWrapper featured cover={cover} key={`${slug}-${title}`}>
        <div className="product-info">
          <Link to={`/soluciones/${slug}`}>
            <h4>{title}</h4>
          </Link>

          <p>{excerpt}</p>
        </div>
        <div className="product-nav">
          <Link to={`/soluciones/${slug}`}>
            <Button title="Ver Más" variant="outlined" />
          </Link>
        </div>
      </SolutionWrapper>
    </GridContainer>
  );
};

export default FeaturedSolution;
