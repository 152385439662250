import React from 'react';
import { Link } from 'gatsby';
import Container from '../../components/UI/Container';
import SolutionListWrapper, { GridContainer } from './solutionlist.style';

import FeaturedSolution from './FeaturedSolution';
import SubFeaturedSolutions from './SubFeaturedSolutions';
import RestOfSolutions from './RestOfSolutions';

const SolutionList = ({ category, title, solutions = [], top }) => {
  if (solutions.length < 1) {
    return null;
  }
  const featured = solutions.slice(0, 1);
  const subFeatured = solutions.slice(1, 3);
  const restSolutions = solutions.slice(3);

  return (
    <SolutionListWrapper top={top}>
      <Container>
        <GridContainer template="70% auto">
          <div className="inner-heading">
            <h3>Soluciones</h3>
            <p>{`En ${title || category.frontmatter.title}`}</p>
          </div>
          <div className="inner-nav">
            <Link to="/soluciones">
              Todas nuestras soluciones 
              {' '}
              <i className="flaticon-next" />
            </Link>
          </div>
        </GridContainer>
        <FeaturedSolution solution={featured[0]} />
        <SubFeaturedSolutions solutions={subFeatured} />
        <RestOfSolutions solutions={restSolutions} />
      </Container>
    </SolutionListWrapper>
  );
};

export default SolutionList;
