import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
// import Button from 'reusecore/src/elements/Button';
// import { Icon } from 'react-icons-kit';
import BannerWrapper from './bannerSection.style';
import FeatureBlock from '../../components/FeatureBlock';
import Container from '../../components/UI/Container';
import SocialShare from '../SocialShare';

const BannerSection = ({ node, row, col }) => {
  if (!node.frontmatter) {
    return <div />;
  }

  const { prefix, title, description, cover } = node.frontmatter;

  return (
    <BannerWrapper id="banner_section" cover={cover}>
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={<Heading as="h1" content={title} />}
              description={<Text content={description} />}
              button={<SocialShare node={node} prefix={prefix} transparent />}
            />
          </Box>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

BannerSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center'
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '60%', '65%']
  }
};

export default BannerSection;
